<template>
  <div class="404">
    <div class="cover-container">
      <div class="cover py-5 bg-brand">
        <img src="@/assets/images/logos/logo.png" alt="" class="" />
      </div>
    </div>

    <div class="px-4 py-5">
      <h1 class="text-center">404</h1>
      <div class="text-sm font-weight-bold h5 mb-4 mt-0 text-center">
        <span class="font-weight-bold h5 my-4 px-3 mt-0">الصفحة غير موجودة</span>
      </div>

      <div>
        <div class="">
          <div class="text-center px-5 my-4">
            <div></div>
          </div>
        </div>
        <div class="text-center p-5">
          <router-link to="/" class="btn btn-success mx-1 mb-2"><i class="la la-home me-2" ></i> الرجوع للصفحة الرئيسية</router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<style>
  
</style>